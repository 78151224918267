import React from "react"
import './CSS/Background.css';


function Background (){


   return (
    <div className="Background-container"  style={{backgroundImage: `url('/Images/BackgroundPicture2.jpeg')`}}>
    
  </div>
);
}

export default Background;