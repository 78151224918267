import React from 'react';
import Bottom from './Bottom.jsx'

import './CSS/LogIn.css';



function LogIn (){

   return (
<div className="LogIn-container">
<div style={{width: "100%", height: "150px"}}></div>
    <div className="LogIn-1">
    <h2 style={{margin: 0, fontSize: 65, fontWeight: 300}}>Log In </h2>
    <p style={{margin: 0, fontSize: 25, fontWeight: 400}}>Aan deze pagina wordt nog gewerkt</p>
    </div>
    <Bottom/>

</div>
    )
}

export default LogIn;