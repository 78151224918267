import React, { useState } from "react"
import Clientinfo from "./Clientinfo/clients.json"
import QuestionCarrousel from "./QuestionCarrousel.jsx";
import GeneralCarrousel from "./GeneralCarrousel.jsx";

import Feed from "./Feed.jsx";
import Intro from "./Intro.jsx";
import { questionCarrouselVisibleValue, answersCarrouselValue, generalCarrouselValue, introVisibleValue, feedVisibleValue, generalCarrouselVisibleValue } from "./QuestionValues.jsx";
import Questioninfo from "./Questions/questions.json"
import Generalinfo from "./General/general.json"

import './CSS/Questionnaire.css';



function Questionnaire (){


    var client = 'client1'
    var questionnaire = "Kunstwijzer"
    var name = <div>Deze pagina is nog beschikbaar<br/>Neem contact op met oli</div>


    if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("questionnaire")){
        questionnaire = Clientinfo[client].questionnaire
    }
    if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("name")){
        name = Clientinfo[client].name
    }


    var questionform = "empty"
    var questioninfo = Questioninfo.empty
    var dict_questionnaire = {}
    var dict_general = {}
    var generalform = "standard"
    var generalinfo = Generalinfo[generalform]



    if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("questionform")){
        questionform = Clientinfo[client].questionform
    }

    if (Questioninfo.hasOwnProperty(questionform)){
        questioninfo = Questioninfo[questionform]
    }

    if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("generalform")){
       generalform = Clientinfo[client].generalform
  }

    for (let i = 0; i < Object.keys(questioninfo).length; i++) {
        dict_questionnaire[i] = { "answer": null };
    }
    for (let i = 0; i < Object.keys(generalinfo).length; i++) {
        dict_general[i] = { "answer": null };
    }

    const [introVisible, setIntroVisible] = useState(true)
    const [generalCarrouselVisible, setGeneralCarrouselVisible] = useState(false)
    const [questionCarrouselVisible, setQuestionCarrouselVisible] = useState(false)
    const [feedVisible, setFeedVisible] = useState(false)
    const [answersCarrousel, setAnswersCarrousel] = useState(dict_questionnaire)
    const [generalCarrousel, setGeneralCarrousel] = useState(dict_general)


    return (

<div style={{width: "100%", minHeight: "100vh", paddingBottom: "50px", backgroundColor: "white", textAlign: "center"}}> 
<div style={{width: "100%", height: "100px", backgroundColor: "white", textAlign: "center"}}> 
</div>
<div className="questionnaire-header">
    {questionnaire}
</div>
<div className="questionnaire-subheader">
    {name}
</div>

<introVisibleValue.Provider value = {{ introVisible, setIntroVisible }}>
<questionCarrouselVisibleValue.Provider value = {{ questionCarrouselVisible, setQuestionCarrouselVisible }}>
<generalCarrouselVisibleValue.Provider value = {{ generalCarrouselVisible, setGeneralCarrouselVisible }}>
<feedVisibleValue.Provider value = {{ feedVisible, setFeedVisible }}>
<generalCarrouselValue.Provider value = {{ generalCarrousel, setGeneralCarrousel }}>
<answersCarrouselValue.Provider value = {{ answersCarrousel, setAnswersCarrousel }}>

{/* {introVisible=== true ? <Intro/>: null}
{generalCarrouselVisible === true ? <GeneralCarrousel/>: null}
{questionCarrouselVisible === true ? <QuestionCarrousel/>: null}
{feedVisible === true ? <Feed/>: null} */}

<div className={introVisible ? 'fade-enter-active' : 'fade-exit-active'}>
{introVisible=== true ? <Intro/>: null}
</div>
<div className={generalCarrouselVisible ? 'fade-enter-active' : 'fade-exit-active'}>
{generalCarrouselVisible === true ? <GeneralCarrousel/>: null}
</div>
<div className={questionCarrouselVisible ? 'fade-enter-active' : 'fade-exit-active'}>
{questionCarrouselVisible === true ? <QuestionCarrousel/>: null}
</div>
<div className={feedVisible ? 'fade-enter-active' : 'fade-exit-active'}>
{feedVisible === true ? <Feed/>: null}
</div>



</answersCarrouselValue.Provider>
</generalCarrouselValue.Provider>
</feedVisibleValue.Provider>
</generalCarrouselVisibleValue.Provider>
</questionCarrouselVisibleValue.Provider>
</introVisibleValue.Provider>
</div>

    )
}

export default Questionnaire;