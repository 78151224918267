import React from 'react';
import Bottom from './Bottom.jsx'

import './CSS/Ervaringen.css';



function Ervaringen (){

   return (
<div className="Ervaringen-container">
<div style={{width: "100%", height: "150px"}}></div>
    <div className="Ervaringen-1">
    <h2 style={{margin: 0, fontSize: 65, fontWeight: 300}}>Ervaringen</h2>
    <p style={{margin: 0, fontSize: 25, fontWeight: 400}}>Aan deze pagina wordt nog gewerkt</p>
    </div>
    <Bottom/>

</div>
    )
}

export default Ervaringen;