import React, { useState, useEffect, useRef } from 'react';
import './CSS/Navbar.css';
import { useNavigate } from 'react-router-dom';


function NavBar (){

    const [show, setShow] = useState(true);
    const lastScrollY = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth > 1000) { // Only apply this logic for screens wider than 1000px
                const currentScrollY = window.scrollY;
                setShow(currentScrollY < lastScrollY.current || currentScrollY < 50);
                lastScrollY.current = currentScrollY;
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate();

    function handleNavigateHome() {
        navigate('/');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateCompetenties() {
        navigate('/Competenties');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }
    
    function handleNavigateSparren() {
        navigate('/Sparren');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateErvaringen() {
        navigate('/Ervaringen');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateOntdek() {
        navigate('/Ontdek');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateOver() {
        navigate('/Over');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateContact() {
        navigate('/Contact');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

    function handleNavigateLogIn() {
        navigate('/LogIn');
        setIsMenuOpen(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };



   return (
    <div className={`Navbar-container ${show ? '' : 'hide-navbar'} ${isMenuOpen ? 'active' : ''}`}>
            
    <div className="menu-icon" onClick={toggleMenu}>
        ☰ {/* Hamburger Icon */}
    </div>

    <div className="Navbar-inner-container">
        <div className="Navbar-inner-container-left">
        <div className="Navbar-inner-container-left-subparts" onClick={handleNavigateCompetenties}>
            Competenties
        </div>
        <div className="Navbar-inner-container-left-subparts" onClick={handleNavigateSparren}>
            Sparren
        </div>
        <div className="Navbar-inner-container-left-subparts" onClick={handleNavigateErvaringen}>
            Ervaringen
        </div>
        </div>
        <div className="Navbar-inner-container-center" onClick={handleNavigateHome}>
            <div className="Navbar-inner-container-center-inside" style={{backgroundImage: `url('/Images/Logo.png')`}}>
                
            </div>
        </div>
        <div className="Navbar-inner-container-right">
        <div className="Navbar-inner-container-right-subparts" onClick={handleNavigateOntdek}>
            Ontdek jouw <br/>toekomst!
        </div>
        <div className="Navbar-inner-container-right-subparts" onClick={handleNavigateOver}>
            Over
        </div>
        <div className="Navbar-inner-container-right-subparts" onClick={handleNavigateContact}>
            Contact
        </div>
        <div className="Navbar-inner-container-right-subparts" onClick={handleNavigateLogIn}>
            Log in
        </div>

    </div>
    </div>
    </div>
    )
}

export default NavBar;