import React, { useRef } from 'react';
import Bottom from './Bottom.jsx'
import { useNavigate } from 'react-router-dom';
import './CSS/Competenties.css';



function Competenties (){

    const targetDivRef = useRef(null);

    // Function to handle the click event
    const scrollToTargetDiv = () => {
        // Use the scrollIntoView method
        targetDivRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };


    const navigate = useNavigate();

    function handleNavigateCompetentiecheck() {
        navigate('/Competentiecheck');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

   return (

<div className="Competenties-container">
<div className="Competenties-1-boven-witte-balk"></div>
    <div className="Competenties-1">
    <div className="Competenties-1-left">
    <div className="Competenties-1-left-1">
        Competenties
    </div>
    <div className="Competenties-1-left-2">
    Word de beste creatieve professional die je kan zijn. Behaal al je doelen! En blijf je ontwikkelen. Kom erachter hoe jij je kan onderscheiden binnen en buiten de creatieve sector. Kom erachter wat jouw competenties zijn en ontdek jouw kansen & mogelijkheden.
    </div>
    <div className="Competenties-1-left-3">
    </div>
    <div className="Competenties-1-left-4">
    Wat zijn competenties?
    </div>
    <div className="Competenties-1-left-5">
    Het is een term die gebruikt wordt om aan te geven wat je kan. Maar wat zijn competenties nou precies? Competenties zijn de samenkomst van je kennis, vaardigheden, wensen en waarden voor een bepaald doel. Je praat natuurlijk anders op een sollicitatiegesprek dan met je vrienden in de kroeg.
    <br/><br/>
    Je competenties blijf je altijd ontwikkelen. Als je iets maar vaak genoeg doet word je er beter in! Maar alles begint bij inzicht in wat jouw competenties en doelen nu zijn. 
    </div>
    <div className="Competenties-1-left-6">
    </div>

    </div>
    <div className="Competenties-1-right">
    <div className="Competenties-1-right-1" style={{backgroundImage: `url('/Images/CompCheck-pic.png')`}}></div>

    <div className="Competenties-1-right-2" onClick={handleNavigateCompetentiecheck}>
    DOE DE COMPETENTIECHECK
    </div>
    </div>
    </div>
    <div className="Competenties-2">
    <div className="Competenties-2-left">
    <div className="Competenties-2-left-1" style={{backgroundImage: `url('/Images/Competenties-2-1.png')`}}></div>
    <div className="Competenties-2-left-2" style={{backgroundImage: `url('/Images/Competenties-2-2.png')`}}></div>
    </div>
    <div className="Competenties-2-right">
    <div className="Competenties-2-right-1">
    Inzicht 
    </div>
    <div className="Competenties-2-right-2">
    Groei door inzicht! Kunstenaars bezitten veel competenties. Het maakt niet je of je er bewust van bent of niet. Veel van die competenties worden steeds belangrijker! Of het nu gaat om artistieke vaardigheden, creatief problemen oplossen of een verhaal vertellen. Door inzicht te krijgen in jouw competenties en waar je die voor wil inzetten weet jij wat jouw volgende stap is. 
    </div>
    <div className="Competenties-2-right-3">
    </div>
    <div className="Competenties-2-right-4">
    De arbeidsmarkt en kansen 
    </div>
    <div className="Competenties-2-right-5">
    Begin met het ontdekken van jouw toekomst! Gebruik de Competentiematrix om meer overzicht te krijgen in jouw kansen en mogelijkheden op de arbeidsmarkt. Leer wat competentiedimensies zijn, welke bij jouw doelen horen en wat op dit moment voor jou belangrijk is. Weten welke competentiedimensies je nu bezit en waar je nog aan kan werken? Doe de CompetentieCheck!
    </div>
    <div className="Competenties-2-right-6">
    <div className="Competenties-2-right-7" onClick={handleNavigateCompetentiecheck}>
        DOE DE COMPETENTIECHECK
    </div>
    <div className="Competenties-2-right-8" onClick={scrollToTargetDiv}>
        NAAR DE COMPETENTIEMATRIX
    </div>
    </div>
    <div className="Competenties-2-right-9">
    </div>
    </div>

    </div>

    <div className="Competenties-3">
    <div className="Competenties-3-left">
    <div className="Competenties-3-left-1" ref={targetDivRef}>
    Competentiematrix
    </div>
    <div className="Competenties-3-left-2">
    De matrix geeft jou inzicht in waar jij met jouw competenties staat op de arbeidsmarkt. Wil je andere soort werk maken? Dan weet je welke competentiedimensie je daarvoor moet ontwikkelen. Iedereen bezit bijna altijd verschillende competentiedimensies, ze sluiten elkaar dus niet uit.
    </div>
    <div className="Competenties-3-left-3"></div>
    <div className="Competenties-3-left-4">
    Competentiedimensies
    </div>
    <div className="Competenties-3-left-5">
    De matrix deelt de arbeidsmarkt op in 4 competentiedimensies. Dat zijn soorten competenties waar je bijvoorbeeld goed in bent of een voorkeur voor hebt. Er is niet 1 dimensie beter dan de rest. Je zit ook niet voor altijd vast aan 1 dimensie. Op de matrix staan de dimensies en voorbeelden van Waarden (W) en Vaardigheden (V) die erbij horen. 
    <br/>
    <br/>
    Hieronder worden de competentie dimensies kort uitgelegd aan de  hand van voorbeelden
    </div>
    <div className="Competenties-3-left-6" style={{backgroundImage: `url('/Images/MatrixArrow.png')`}}>

    </div>
    </div>
    <div className="Competenties-3-right">
    <div className="Competenties-3-right-1" style={{backgroundImage: `url('/Images/Competentie Matrix.png')`}}>

    
    </div>
    </div>
    </div>
    <div className="Competenties-4">
    <div className="Competenties-4-top">
    <div className="Competenties-4-top-1">
    Commercieel gericht
        </div>
        <div className="Competenties-4-top-2">
        Jij als kunstenaar houdt rekening met trends zodat de verkoopwaarde van je werk stijgt. Je staat er voor open om compromissen te sluiten tussen je artistieke proces en de verkoopbaarheid van je werk. Kunst maak je om niet alleen van te leven maar ook om er (wat) aan te verdienen.
        </div>
        </div>
        <div className="Competenties-4-middle">
        <div className="Competenties-4-middle-1">
        <div className="Competenties-4-middle-1-left">
        <div className="Competenties-4-middle-1-left-1">
        Intern
        </div>
        <div className="Competenties-4-middle-1-left-2">
        Jij maakt kunst voor de kunst. Creativiteit en creëren zijn jouw allerbelangrijkste doelen. Het maakt je minder uit wie het werk bereikt en of je er voor betaald kan worden.        
</div>
</div>
</div>

<img src="/Images/Logo.png" alt="Description" className="Competenties-4-middle-2"/>
<div className="Competenties-4-middle-1">
        <div className="Competenties-4-middle-3-left">
        <div className="Competenties-4-middle-3-left-1">
        Extern
        </div>
        <div className="Competenties-4-middle-3-left-2">
        Jij bent de verbinder. Je brengt mensen graag samen binnen of buiten de creatieve sector. Jij brengt  de waarde van kunst op mensen.
        </div>
</div>
</div>
        </div>
        <div className="Competenties-4-bottom">
    <div className="Competenties-4-bottom-1">
    Maatschappelijk gericht        </div>
        <div className="Competenties-4-bottom-2">
        Jij start graag het gesprek. Je geeft mensen een stem die dat niet hebben. Wat jij bij wil dragen is niet altijd makkelijk, esthetisch of handig. Je draagt graag bij aan de wereld om je heen        </div>
        </div>
        </div>
        <div className="Competenties-5">
        <div className="Competenties-5-inner">
        <div className="Competenties-5-inner-left">
        <div className="Competenties-5-inner-left-1">
        Over tot actie!
            </div>
            <div className="Competenties-5-inner-left-2">
            Bepaal je volgende stap! Blijf je ontwikkelen. Kom erachter waar jij staat op de Competentiematrix. Vanaf daar kan jij jouw pad gaan ontdekken.
<br/><br/>
Wil je meer weten over jouw competenties, competentiedimensies of heb je een hele andere vraag? Neem dan  contact op. Plan een spar-gesprek en kom meer te weten over jezelf, jouw doelen en je kansen.
<br/><br/>
Sluit je aan bij het groeiende netwerk van jonge kunstenaars. Leer van de echte ervaringen van anderen. Want samen staan we sterker.
            </div>
            <div className="Competenties-5-inner-left-3" style={{backgroundImage: `url('/Images/Competenties-2-1.png')`}}>
            
            </div>
            </div>
            <div className="Competenties-5-inner-right">
        <div className="Competenties-5-inner-right-1">
        Zet de volgende stap! 
            </div>
            <div className="Competenties-5-inner-right-2" style={{backgroundImage: `url('/Images/arrow.png')`}}>
            
            </div>
            <div className="Competenties-5-inner-right-3" onClick={handleNavigateCompetentiecheck}>
           DOE DE COMPETENTIECHECK
            </div>
            
            </div>
        </div>
        </div>
        <div className="Competenties-6">
            De CompetentieCheck
        </div>
        <div className="Competenties-7">
        <div className="Competenties-7-left">
        <div className="Competenties-7-left-1">
        Maak het plan voor jouw volgende stap 
        </div>
        <div className="Competenties-7-left-2">
        7 vragen die jou aanzetten tot denken en het begin vormen voor meer inzicht in competenties, doelen & kansen.
        </div>
        <div className="Competenties-7-left-3" onClick={handleNavigateCompetentiecheck}>
            NAAR DE COMPETENTIECHECK
        </div>
        <div className="Competenties-7-left-4">
        </div>
        </div>
        <div className="Competenties-7-right">
        <div className="Competenties-7-right-inner" style={{backgroundImage: `url('/Images/RouteVlag.png')`}}>

        </div>
        </div>
        </div>

    <Bottom/>
</div>

    )
}

export default Competenties;