import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CSS/Homepage.css';
import Bottom from './Bottom.jsx'


function Homepage (){

    const navigate = useNavigate();

    function handleNavigateCompetentiecheck() {
        navigate('/Competentiecheck');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateOver() {
        navigate('/Over');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateOntdek() {
        navigate('/Ontdek');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

   return (
<div className="Homepage-container">
<div className="Homepage-1">

    <div style={{width: "100%", height: "150px"}}></div>
    <div className="homepage-1">
      <div className="homepage-1-content">
        <div className="homepage-1-img" style={{backgroundImage: `url('/Images/Logo uitgeschreven.png')`}}> </div>
      
        <div className="homepage-1-subtext">
          van academie naar carrière
        </div>
        <div className="homepage-1-checkbutton" onClick={handleNavigateCompetentiecheck}>
        DOE DE <br/> COMPETENTIECHECK
      </div>
        </div>
    </div>
   
    </div>
    <div className="Homepage-2">
        <div className="Homepage-2-yellow-pointy">
            <div className="Homepage-2-text">
            Welkom bij KunstenaarsKompas, het platform voor de startende kunstenaar. Ontdek hoe jij je kan blijven ontwikkelen om al jouw professionele doelen te behalen. Maak de stap van academie naar carrière makkelijker door te leren van en samen met andere kunstenaars. Geef richting aan jouw carrière en focus op wat belangrijk is.
            </div>
        </div>
        <div style={{height: "100px"}}></div>
        <div className="Homepage-2-text-2-container">
          <div className="Homepage-2-text-2-left">
            <div className="Homepage-2-text-2-left-header">
            Zet de volgende stap!
            </div>
            <div className="Homepage-2-text-2-left-text">
            KunstenaarsKompas gelooft in de kracht van kunst en creativiteit. Kunstenaars maken de wereld mooier, brengen onderwerpen aan het licht, zorgen voor verbinding en veel meer. Kunstenaars zijn essentieel voor vooruitgang. Daarom wil KunstenaarsKompas kunstenaars helpen en motiveren in hun ontwikkeling richting al hun professionele doelen. KunstenaarsKompas brengt creatieven samen om samen te groeien. Iedereen verdient een kans zijn droom achterna te gaan, om de volgende stap te zetten.
            </div>
            <div className="Homepage-2-text-2-left-button" onClick={handleNavigateOver}>
              LEES MEER
            </div>
            </div>
            <div className="Homepage-2-text-2-middle" style={{backgroundImage: `url('/Images/Footprint.png')`}}>
            </div>
            <div className="Homepage-2-text-2-right">
            <div className="Homepage-2-text-2-right-up">
              Join het netwerk!
            </div>
            <div className="Homepage-2-text-2-right-middle" style={{backgroundImage: `url('/Images/arrow.png')`}}>
            </div>
            <div className="Homepage-2-text-2-right-bottom" onClick={handleNavigateOntdek}>
            Ontdek jouw <br/>toekomst
            </div>
            </div>
          </div>
          <div className="Homepage-3-blue-pointy">
            <div className="Homepage-3-header">
            Ontdek jouw kansen
            </div>
            <div className="Homepage-3-subheader">
            Kom erachter welke stap jij gaat zetten
            </div>
            <div className="Homepage-3-container">
            <div className="Homepage-3-innercontainer">
            <div className="Homepage-3-innercontainer-picture" style={{backgroundImage: `url('/Images/Competenties-pic.png')`}}>
            </div>
            <div className="Homepage-3-innercontainer-header">
              Competenties
            </div>
            <div className="Homepage-3-innercontainer-text">
            Krijg inzicht in jouw competenties en kansen op de arbeidsmarkt met de CompetentieCheck en Competentiematrix. Zodat jij je kan blijven ontwikkelen.
            </div>
            </div>
            <div className="Homepage-3-innercontainer">
            <div className="Homepage-3-innercontainer-picture" style={{backgroundImage: `url('/Images/Netwerk-pic.png')`}}>
            </div>
            <div className="Homepage-3-innercontainer-header">
              Netwerk
            </div>
            <div className="Homepage-3-innercontainer-text">
            Leer van de ervaringen van anderen. Bespreek thema’s en vragen. Kom tot nieuwe inzichten, samenwerkingen en zelfs vrienden. Samen staan we sterker.  Sluit je aan bij het platform.
            </div>
            </div>
            <div className="Homepage-3-innercontainer">
            <div className="Homepage-3-innercontainer-picture" style={{backgroundImage: `url('/Images/Sparren-pic.png')`}}>
            </div>
            <div className="Homepage-3-innercontainer-header">
              Sparren
            </div>
            <div className="Homepage-3-innercontainer-text">
            Samen in gesprek gaan over jouw  professionele doelen en de weg daarnaartoe. Aan de hand van persoonlijke diepgang en modellen, zoals de IKIGAI, weet jij hoe en welke stap te zetten. 
            </div>
            </div>
            </div>
        </div>
        <div className="Homepage-4">
        <div className="Homepage-4-inner">
        <div className="Homepage-4-inner-left">

        <div className="Homepage-4-inner-header">
        ‘Nu ik onderdeel ben van het netwerk van KunstenaarsKompas heb ik mij verder kunnen ontwikkelen.’
</div>    
<div className="Homepage-4-inner-subheader">
'Door alles op een rijtje te zetten en vragen te bespreken met mensen die ik eerst niet kende heb ik veel over mijzelf geleerd. Hierdoor weet ik nu beter wat nu voor mij belangrijk is, waar ik op moet focussen.'  
</div>   
<div className="Homepage-4-inner-author">
Eva de Groot, schrijver, 23 jaar
</div>     
</div>
<div className="Homepage-4-inner-right">
<div className="Homepage-4-inner-picture" style={{backgroundImage: `url('/Images/Person 2.jpeg')`}}>
</div>

</div>
</div>
<div className="Homepage-4-inner">
<div className="Homepage-4-inner-left">
        <div className="Homepage-4-inner-header">
        ‘Heel fijn dat de spar-sessies van KunstenaarsKompas niet zomaar een netwerkborrel zijn’</div>    
<div className="Homepage-4-inner-subheader">
‘Het is lastig zelf structuur aan te brengen in je kunstenaarsleven, maar door het sparren en behandelen van de IKIGAI weet ik nu beter hoe ik dat ga aanpakken.’</div>   
<div className="Homepage-4-inner-author">
Lucie Collem, digitaal kunstenaar, 26 jaar
</div>     
</div>
<div className="Homepage-4-inner-right">
<div className="Homepage-4-inner-picture" style={{backgroundImage: `url('/Images/Person .png')`}}>
</div>
</div>
</div>

        </div>
        <div className="Homepage-5">
        <div className="Homepage-5-inner">
        <div className="Homepage-5-left">
  Leer meer van elkaar
  </div>        
  <div className="Homepage-5-middle" style={{backgroundImage: `url('/Images/arrow.png')`}}>
  </div>        
  <div className="Homepage-5-right" onClick={handleNavigateOver}>
  Proces <br/>
  Professionaliteit <br/>
  Focus <br/>
  Effect van KK <br/>
 
  </div>
  </div>
</div>
    </div>
    
    <Bottom/>
</div>

    )
}

export default Homepage;