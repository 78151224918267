import React from 'react';
import Bottom from './Bottom.jsx'

import './CSS/Ontdek.css';



function Ontdek (){

   return (
<div className="Ontdek-container">
<div style={{width: "100%", height: "150px"}}></div>
    <div className="Ontdek-1">
    <h2 style={{margin: 0, fontSize: 65, fontWeight: 300}}>Ontdek jouw toekomst</h2>
    <p style={{margin: 0, fontSize: 25, fontWeight: 400}}>Aan deze pagina wordt nog gewerkt</p>
    </div>
    <Bottom/>

</div>
    )
}

export default Ontdek;