import React, { useState, useContext } from "react"
import Clientinfo from "./Clientinfo/clients.json"
import Questioninfo from "./Questions/questions.json"
import './CSS/QuestionnaireCarrousel.css';
import { questionCarrouselVisibleValue, answersCarrouselValue, feedVisibleValue } from "./QuestionValues.jsx";


function QuestionnaireCarrousel (){

    var client = 'client1'

    var questionform = "empty"
    
    var questioninfo = Questioninfo.empty

    if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("questionform")){
        questionform = Clientinfo[client].questionform
    }

    if (Questioninfo.hasOwnProperty(questionform)){
        questioninfo = Questioninfo[questionform]
    }



    const [currentSlide, setCurrentSlide] = useState(0);
    const [met, setMet] = useState(true);

    // eslint-disable-next-line
    const { questionCarrouselVisible, setQuestionCarrouselVisible } =  useContext(questionCarrouselVisibleValue)
    // eslint-disable-next-line
    const { feedVisible, setFeedVisible } =  useContext(feedVisibleValue)
    
    const { answersCarrousel, setAnswersCarrousel } =  useContext(answersCarrouselValue)

    
    const slides = Object.keys(questioninfo);
    const goToNextSlide = () => {
      setCurrentSlide((prevSlide) => {
        if (prevSlide !== Object.keys(questioninfo).length - 1) {
          return prevSlide + 1;
        } else {
          return prevSlide;
        }
      });
    };
  
    // const goToPreviousSlide = () => {
    //   setCurrentSlide((prevSlide) =>
    //     prevSlide === 0 ? Object.keys(questioninfo).length - 1 : prevSlide - 1
    //   );
    // };

    const slideStyles = {
      // transform: `translateX(-${currentSlide * 100}%)`,
      transform: `translateY(-${currentSlide * 1080}px)`,
      transition: 'transform 0.5s ease-in-out',
      // display: 'flex',
      display: 'block',
      height: '500px',
      width: '100%',
      zIndex: 8, 
      position: 'relative'
    };
  
    const saveAnswers = (answer) => {
     var splitString = answer.split(" ");
     var questionNumber = splitString[0]
     var answerNumber = splitString[1]
     var answerCategory = splitString[2]
      var subScores = {...answersCarrousel}
      subScores[questionNumber] = {...subScores[questionNumber], answer: {"answerNumber": answerNumber, "answerCategory": answerCategory}}
      setAnswersCarrousel(subScores)
     var hasNullValues = Object.values(subScores).some(item => item.answer === null);
      if (!hasNullValues){setMet(false)}
    };

    const handleClick = () => {
      setQuestionCarrouselVisible(false)
      setFeedVisible(true)
    };

    const navigateToSlide = (NavigateTo) => {
      setCurrentSlide(NavigateTo)
    }
    
     

    return (
      <div style={{ width: '100%', overflow: 'hidden'}}>
        <div>
          <div style={{width: `calc(80% + 20px)`, marginLeft: `calc(10% - 10px)`, zIndex: 10, position: 'relative', backgroundColor: 'white'}}>
          {slides.map((slide, index) => (
            <div key= {"questionnumber " + index} onClick={function(event){navigateToSlide(index)}} style={{display: "inline-block", width: `calc((100%/ ${slides.length})`}} className="questionNumber-container" >
              <div className={`questionNumber ${currentSlide === index ? "selectedquestion" : (answersCarrousel[index].answer !== null ? "answeredquestion" : "")}`}>
              {index+1} 
              </div>
              </div> ))}
          </div>
        <div style={slideStyles}>
          {slides.map((slide, index) => (
            <div key={index} style={{ flex: '0 0 100%', width: '100%', marginBottom: '600px'}}>
                <div style={{ width: '80%', marginLeft: `calc(10% - 10px)`, height: '460px', backgroundColor: 'white', borderStyle: 'solid', borderWidth: '10px', borderColor: '#5A5A5A', borderRadius: '15px'}}>
                <div className="question">
                {questioninfo[slide].question}
              </div>
              {questioninfo[slide].answers.map((answerMap, indexx) => (
                <div key={index + " " + indexx} onClick= {function(event){ goToNextSlide(); saveAnswers(index + " " + indexx + " " + answerMap.category)}} className={`answer ${answersCarrousel[index] && answersCarrousel[index].answer && indexx.toString() === answersCarrousel[index].answer.answerNumber ? "selected" : ""}`}>
                {answerMap.answer}
                </div>
              ))}
              <br/>
              {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={goToPreviousSlide} disabled={currentSlide === 0}>
            Previous
          </button>
          <button
            onClick={goToNextSlide}
            disabled={currentSlide === Object.keys(questioninfo).length - 1}
          >
            Next
          </button>
        </div> */}
        <div>
      <button onClick={handleClick} disabled={met}>
        Resultaten
      </button>
      </div>
              </div>
            </div>
          ))}
        </div>
        
        </div>
      </div>
    );
  };
  
export default QuestionnaireCarrousel;