import React, { useRef, useState} from "react"
import { scrollValue } from "./QuestionValues.jsx";
import Questionnaire from "./Questionnaire.jsx";

import './CSS/Competentiecheck.css';



function Competentiecheck (){

    const [scroll, setScroll] = useState(false)
const myRef = useRef();

if (scroll === true){
    myRef.current.scrollIntoView({});
}

   return (
<div className="Competentiecheck-container">
<div style={{width: "100%", height: "100vh", position: "fixed", top: "0", backgroundColor: "white"}}></div>
<scrollValue.Provider value = {{ scroll, setScroll }}>
    <div ref={myRef}>
<Questionnaire/>
</div>

</scrollValue.Provider>
</div>

    )
}

export default Competentiecheck;