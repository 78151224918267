import React from 'react';
import Bottom from './Bottom.jsx'
import { useNavigate } from 'react-router-dom';
import './CSS/Sparren.css';



function Sparren (){

  const navigate = useNavigate();

  function handleNavigateOntdek() {
      navigate('/Ontdek');
      setTimeout(() => {
        window.scrollTo(0, 0);
    }, 150);  }

   return (
<div className="Sparren-container">

    <div className="Sparren-1-boven-witte-balk"></div>
      <div className="Sparren-1">
      <div className="Sparren-1-inner-content-sub">
        <h2 className="Sparren-1-inner-content-sub-header">
            Sparren 
            </h2>
        <p className="Sparren-1-inner-content-sub-content">
        Kort intro over wat er in dit artikel te lezen is. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
        </div>

      <div className="Sparren-1-inner-content-sub">
        <h2 className="Sparren-1-inner-content-sub-header">
            Hoe werkt het? 
            </h2>
        <p className="Sparren-1-inner-content-sub-content">
        De opzet, de uitwerking, de opties, 1op1, groepen, samenwerkingen, het netwerk teasen. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div>
      </div>
      <div className="Sparren-1-1">
        <div className="Sparren-1-1-lines">

        </div>
        <div className="Sparren-1-1-lines">

        </div>
        </div>

        <div className="Sparren-1-2">
        <div className="Sparren-1-2-images" style={{backgroundImage: `url('/Images/Sparren-2.png')`}}>
        </div>
        <div className="Sparren-1-2-images" style={{backgroundImage: `url('/Images/Sparren-3.png')`}}>
        </div>
        <div className="Sparren-1-2-images" style={{backgroundImage: `url('/Images/Sparren-1.png')`}}>
        </div>
    </div>

    <div className="Sparren-2" style={{backgroundImage: `url('/Images/crumbled paper.jpg')`}}>
      <div className="Sparren-2-text">
      <h2 className="Sparren-2-header">
      Een kijkje in de toolbox
      </h2>  
      <h4 className="Sparren-2-subheader">
      Deze tools en meer kunnen aan bod komen in jouw spar-sessie
      </h4>
      </div>
      <div className="Sparren-2-items">
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      <div className="Sparren-2-item" style={{backgroundImage: `url('/Images/maslow pyramid.png')`}}>
      </div>
      </div>
    </div>

    <div className="Sparren-3">
      <div className="Sparren-3-1" style={{backgroundImage: `url('/Images/Competenties-2-1.png')`}}>

      </div>
      <div className="Sparren-3-2">
      <div className="Sparren-3-2-1">
        <div className="Sparren-3-2-1-1">
        Ook een keer sparren? 
        <br/>
        Neem contact op!
        </div>
        <div className="Sparren-3-2-1-2">
        Klaar om de meer te leren over jezelf en jouw doelen. 
        <br/>
        Neem dan nu contact op en blijf ontwikkelen
        </div>
        </div>
        <div className="Sparren-3-2-2">
        
        <div className="Sparren-3-2-2-1" onClick={handleNavigateOntdek}>
        ONTDEK JOUW TOEKOMST
      </div>
      </div>

      </div>
      <div className="Sparren-3-3" style={{backgroundImage: `url('/Images/MatrixArrow.png')`}}>
      </div>


    </div>

    <Bottom/>

</div>
    )
}

export default Sparren;