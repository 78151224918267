import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Homepage from "./Homepage.jsx";
import Competenties from "./Competenties.jsx";
import Sparren from "./Sparren.jsx";
import Ervaringen from "./Ervaringen.jsx";
import Ontdek from "./Ontdek.jsx";
import Over from "./Over.jsx";
import Contact from "./Contact.jsx";
import LogIn from "./LogIn.jsx";
import Competentiecheck from "./Competentiecheck.jsx";


// import Productspage from "./Products.jsx";
// import Aboutuspage from "./AboutUs.jsx";
// import Contactpage from "./Contact.jsx";
// import NotFound from "./NotFound.jsx";
// import Websitespage from "./Websitespage.jsx";
// import Webappspage from "./Webappspage.jsx";
// import Mobileappspage from "./Mobileappspage.jsx";
// import Digitalsolutionspage from "./Digitalsolutionspage.jsx";

import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes (){
    const location = useLocation();

   return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Homepage />} />
            <Route path="/Competenties" element={<Competenties />} />
            <Route path="/Sparren" element={<Sparren />} />
            <Route path="/Ervaringen" element={<Ervaringen />} />
            <Route path="/Ontdek" element={<Ontdek />} />
            <Route path="/Over" element={<Over />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/LogIn" element={<LogIn />} />
            <Route path="/Competentiecheck" element={<Competentiecheck />} />

            {/* <Route path="/Products" element={<Productspage />} />
            <Route path="/AboutUs" element={<Aboutuspage />} />
            <Route path="/Contact" element={<Contactpage />} />
             />

            <Route path="/Websitespage" element={<Websitespage />} />
            <Route path="/Webappspage" element={<Webappspage />} />
            <Route path="/Mobileappspage" element={<Mobileappspage />} />
            <Route path="/Digitalsolutionspage" element={<Digitalsolutionspage />} /> */}

          </Routes>
    </AnimatePresence>
    )
}

export default AnimatedRoutes;