import React, { useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { generalCarrouselVisibleValue, questionCarrouselVisibleValue, generalCarrouselValue, scrollValue } from "./QuestionValues.jsx";
import general from "./General/general.json"
import Clientinfo from "./Clientinfo/clients.json"
import './CSS/GeneralCarrousel.css';



function GeneralCarrousel (){
  // eslint-disable-next-line
  const { generalCarrouselVisible, setGeneralCarrouselVisible } =  useContext(generalCarrouselVisibleValue)
  // eslint-disable-next-line
  const { questionCarrouselVisible, setQuestionCarrouselVisible } =  useContext(questionCarrouselVisibleValue)

  const { generalCarrousel, setGeneralCarrousel } =  useContext(generalCarrouselValue)
  const [met, setMet] = useState(true);
    // eslint-disable-next-line
    const { scroll, setScroll } =  useContext(scrollValue)

  const [typedName, setTypedName] = useState('');
  const [typedHBO, setTypedHBO] = useState('');
  const [typedEdu, setTypedEdu] = useState('');



  const location = useLocation();
  var clientname = location.pathname.toLowerCase();
  var client = clientname.replace("/", "");
  
  var generalinfo = "standard"

  if (Clientinfo && Clientinfo[client] && Clientinfo[client].hasOwnProperty("generalform")){
    generalinfo = Clientinfo[client].generalform
}

const generalslides = Object.keys(general[generalinfo]);

  const handleClick = () => {
    setGeneralCarrouselVisible(false)
    setQuestionCarrouselVisible(true)
    setScroll(true)
  };

  function saveAnswers(answer) {
    var questionNumber = answer[0]
    var answergeneral = answer[1]
    var subScores = {...generalCarrousel}
    subScores[questionNumber] = {...subScores[questionNumber], answer: answergeneral}
    setGeneralCarrousel(subScores)
    var hasNullValues = Object.values(subScores).some(item => item.answer === null);
      if (!hasNullValues){setMet(false)}
  }

  const handleInputChangeName = (event) => {
    setTypedName(event.target.value);
  };
function saveTypedAnswerName(questionnumber, event){
  event.preventDefault();
  var subScores = {...generalCarrousel}
  subScores[questionnumber] = {...subScores[questionnumber], answer: typedName}
    setGeneralCarrousel(subScores)
    var hasNullValues = Object.values(subScores).some(item => item.answer === null);
      if (!hasNullValues){setMet(false)}
      setTypedName('')
}

const handleInputChangeHBO = (event) => {
  setTypedHBO(event.target.value);
};
function saveTypedAnswerHBO(questionnumber, event){
  event.preventDefault();
  var subScores = {...generalCarrousel}
  subScores[questionnumber] = {...subScores[questionnumber], answer: typedHBO}
    setGeneralCarrousel(subScores)
    var hasNullValues = Object.values(subScores).some(item => item.answer === null);
      if (!hasNullValues){setMet(false)}
      setTypedHBO('')
}

const handleInputChangeEdu = (event) => {
  setTypedEdu(event.target.value);
};


function saveTypedAnswerEdu(questionnumber, event){
  event.preventDefault();
  var subScores = {...generalCarrousel}
  subScores[questionnumber] = {...subScores[questionnumber], answer: typedEdu}
  setGeneralCarrousel(subScores)
  var hasNullValues = Object.values(subScores).some(item => item.answer === null);
    if (!hasNullValues){setMet(false)}
    setTypedEdu('')
}



    return (
      <div>
        <div className="general-container">
       {generalslides.map((gslide, index) => (
            <div key={index} className="genQuestion">
            {general[generalinfo][gslide].question}
            {general[generalinfo][gslide].answers.map((ganswer, indexx) => (
              <div key={indexx+'second'}>
                {(ganswer !== 'input' && ganswer !== 'Andere opleiding:' && ganswer !== 'Andere opleidingsinstelling:') && (
                <div onClick={function(event){ saveAnswers([index, ganswer])}} className={generalCarrousel[index].answer !== ganswer ? 'non-null-class' : 'null-class'} >
                  {ganswer}
                  </div>
                )}
                {(ganswer === 'input') && (
                  <div className='non-null-class'>
                  <div className={generalCarrousel[index].answer === ganswer ? 'non-null-class' : 'null-class'} style={{margin: '0px'}}>
                {generalCarrousel[index].answer}
                </div>
                <form onSubmit={ function(event){ saveTypedAnswerName(index, event)} }>
                  <div>
                  <input type="text" value={typedName} onChange={handleInputChangeName}/>
                  </div>
                  <div>
                  <button type="submit" className="genbutton">
                 submit
                </button>
                </div>
                </form>
                </div>
                  )}
                  {(ganswer === 'Andere opleidingsinstelling:') && (
                  <div className='non-null-class'>
                  {ganswer}
                  <div className={generalCarrousel[index].answer === ganswer ? 'non-null-class' : 'null-class'} style={{margin: '0px'}}>
                  {!general[generalinfo].question3.answers.includes(generalCarrousel[index].answer) && generalCarrousel[index].answer}
                </div>
                <form onSubmit={ function(event){ saveTypedAnswerHBO(index, event)} }>
                <div>
                  <input type="text" value={typedHBO} onChange={handleInputChangeHBO}/>
                  </div>
                  <div>
                  <button type="submit" className="genbutton">
                 submit
                </button>
                </div>
                </form>
                </div>
                  )}
                {(ganswer === 'Andere opleiding:') && (
                  
                  <div className='non-null-class'>
                  {ganswer}
                 <div className={generalCarrousel[index].answer === ganswer ? 'non-null-class' : 'null-class'} style={{margin: '0px'}}>
                  {!general[generalinfo].question4.answers.includes(generalCarrousel[index].answer) && generalCarrousel[index].answer}
                </div>
                <form onSubmit={ function(event){ saveTypedAnswerEdu(index, event)} }>
                <div>
                  <input type="text" value={typedEdu} onChange={handleInputChangeEdu}/>
                  </div>
                  <div>
                  <button type="submit" className="genbutton">
                 submit
                </button>
                </div>
                </form>
                </div>
                  )}
              </div>
               ))}
            </div>
              ))}

        </div>

        <button onClick={handleClick} disabled={met} className={met === true ? 'genbuttondisable' : "genbutton"}>
        Naar de test
        </button>
      </div>
    );
  };
  
export default GeneralCarrousel;