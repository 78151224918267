import React from "react";
import { BrowserRouter } from "react-router-dom";

import NavBar from "./NavBar.jsx";
import Background from "./Background.jsx"

import './CSS/default.css';

import AnimatedRoutes from "./AnimatedRoutes.jsx";

function Router() {

  return (
    <div>
        <BrowserRouter>


        <NavBar/>
        <Background/>

        <AnimatedRoutes/>
        </BrowserRouter>
    </div>
  );
}

export default Router;
