import React, { useContext } from "react"
import { generalCarrouselVisibleValue, introVisibleValue } from "./QuestionValues.jsx";
import './CSS/Intro.css';



function Intro (){
  
// eslint-disable-next-line
const { generalCarrouselVisible, setGeneralCarrouselVisible } =  useContext(generalCarrouselVisibleValue)
// eslint-disable-next-line
const { introVisible, setIntroVisible } =  useContext(introVisibleValue)

  const handleClick = () => {
    setIntroVisible(false)
    setGeneralCarrouselVisible(true)
  };

    return (
      <div>
        <div className="intro-header">
        Wat is het?
        </div>

        <div className="long-intro-container">
        <div className="long-intro-text">      
        Deze vragenlijst brengt in kaart welke vaardigheden en waarden bij jou als maker passen. De uitslag van deze vragenlijst geeft een beeld van waar jij als maker staat in relatie tot de arbeidsmarkt. Het laat bijvoorbeeld zien welke sector het best bij je past als het gaat om kansen en potentiële opdrachtgevers. Ook maakt het duidelijk waar je misschien nog aan moet werken mocht je aan de slag willen gaan in een bepaalde sector of voor een opdrachtgever. 
        <br/><br/>
        Voor het invullen van deze vragenlijst is een aantal zaken belangrijk om in acht te nemen. Ten eerste is het resultaat van deze vragenlijst is een momentopname, het geeft actuele voorkeuren weer en kan veranderen over tijd en per situatie. Selecteer het antwoord dat het best bij jou past. Ten tweede, de vragenlijst is bewust kort gehouden voor een eenduidige uitkomst. Tot slot, vul bij iedere vraag in wat het best bij jou als maker past.
        </div>
        </div>
        
        <button onClick={handleClick}>
        Naar de test
        </button>
        <div className="disclaimer">
        Disclaimer: KunstenaarsKompas verwerkt jouw keuzes en gegevens anoniem volgens de privacyverklaring. 
        </div>
      </div>

    );
  };
  
export default Intro;