import React from 'react';
import './CSS/Bottom.css';
import { useNavigate } from 'react-router-dom';



function Bottom (){

    const navigate = useNavigate();

    function handleNavigateCompetenties() {
        navigate('/Competenties');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateCompetentieCheck() {
        navigate('/CompetentieCheck');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateSparren() {
        navigate('/Sparren');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateErvaringen() {
        navigate('/Ervaringen');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      function handleNavigateOver() {
        navigate('/Over');
        setTimeout(() => {
          window.scrollTo(0, 0);
      }, 150);  }

      const handleCodeStartClick = () => {
        // Redirect to the URL
        window.location.href = 'https://code-start.com';
      };

   return (
<div className="Bottom-container">
    <div className="Bottom-container-1">
        <div className="Bottom-container-1-sub">
            <div className="Bottom-1-Header" onClick={handleNavigateCompetenties}>
                Competenties
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateCompetenties}>
                Wat zijn competenties?
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateCompetenties}>
                Competentiematrix
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateCompetentieCheck}>
                De CompetentieCheck
            </div>
        </div>
        <div className="Bottom-container-1-sub">
        <div className="Bottom-1-Header" onClick={handleNavigateSparren}>
                Sparren
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateSparren}>
                Hoe werkt het
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateSparren}>
                1-op-1
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateSparren}>
                Samen ontdekken
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateSparren}>
                Maak een afspraak
            </div>
        </div>
        <div className="Bottom-container-1-sub" onClick={handleNavigateErvaringen}>
        <div className="Bottom-1-Header" onClick={handleNavigateErvaringen}>
                Ervaringen 
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                Netwerken 
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                Ontwikkeling
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                Focus
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                Balans
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                Inkomen
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateErvaringen}>
                KunstenaarsKompas
            </div>
        </div>
        <div className="Bottom-container-1-sub" onClick={handleNavigateOver}>
        <div className="Bottom-1-Header">
                Over
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateOver}>
                Missie en Visie
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateOver}>
                Contact
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateOver}>
                Privacy verklaring
            </div>
            <div className="Bottom-1-SubHeader" onClick={handleNavigateOver}>
                Algemene voorwaarden
            </div>
        </div>
            
    </div>
    <div className="Bottom-container-2">
    <div className="Bottom-container-2-Header">
        In samenwerking met
    </div>
    <div className="Bottom-container-2-Logos" >
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 1.png')`}}>
    </div>
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 2.png')`}}>
    </div>
    <div className="Bottom-container-2-Logo" onClick={handleCodeStartClick} style={{backgroundImage: `url('/Images/code-start.png')`, cursor: 'pointer'}}>
    </div>
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 4.png')`}}>
    </div>
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 1.png')`}}>
    </div>
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 2.png')`}}>
    </div>
    <div className="Bottom-container-2-Logo" onClick={handleCodeStartClick} style={{backgroundImage: `url('/Images/code-start.png')`, cursor: 'pointer'}}>
    </div>
    <div className="Bottom-container-2-Logo" style={{backgroundImage: `url('/Images/HKU Logo 4.png')`}}>
    </div>
    </div>
    </div>
    </div>
    )
}

export default Bottom;